
<template>
    <div class="wchat-box">
        <CContainer>
        <CRow class="justify-content-center" style="margin-top:1rem">
          <CCol md="8">
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                    <div class="wma-box">
                        <div class="tip">{{ $t('WechatBind.Tip') }}<span @click="toReg" class="rg-active">{{ $t('WechatBind.Register') }}</span>）</div>
                        <div class="form-box">
                            <div class="em">
                                <div class="label">{{ $t('WechatBind.Email') }}</div>
                                <CInput v-model="em" type="email" />
                            </div>
                            <div class="paw">
                                <div class="label">{{ $t('WechatBind.Password') }}</div>
                                <CInput v-model="paw" type="password" />
                            </div>
                            <div class="sub-box">
                                <button
                                    @click="submitBind"
                                    class="btn custer sbtn"
                                    type="button"
                                >
                                    {{ $t('WechatBind.Submit') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        </CContainer>
    </div>
</template>

<script>
import { wxLogin } from "../../api/http"
export default {
    data() {
        return {
            em: "",
            paw: ""
        }
    },
    created() {
        // this.submitBind()
    },
    methods: {
        toReg() {
            this.$router.push("/pages/register")
        },
        // 提交綁定
        submitBind() {
            if(this.em == "" || this.paw == "") {
                return
            }
            let obj = {
                type: "wx_scan_login",
                qr_id: this.$route.query.qr_id,
                user_name: this.em,
                password: this.paw
            }
            wxLogin(obj).then((res) => {
                console.log('res==>', res)
                if(res.code == 200) {
                    sessionStorage.setItem("isLogin", 1);
                    this.$message.success(res.message);
                    this.$store.commit("setUserInfo", res.data);

                    const uid = res.data.map((item) => item.id);
                    const user_name = res.data.map((item) => item.user_name);
                    const is_agent = res.data.map((item) => item.is_agent)
                    window.localStorage.setItem("uid", uid);
                    window.localStorage.setItem("user_name", user_name);
                    window.localStorage.setItem("is_agent", is_agent)
                    // this.$i18n.locale = 'tw'

                    this.$router.push({ path: "/dashboard" });
                }else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style scoped>
.form-box{
    padding: 23px 176px;
}
.label{
    margin-bottom: .3rem;
}
.tip{
    text-align: center;
    color: #FAC11D;
}
.rg-active{
    color: rgba(0, 190, 164);
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.1rem
}
.rg-active:hover{
    border-bottom: 1px solid;
}
.sbtn:hover {
  background: rgba(0, 190, 164, 0.8) !important;
}
.sbtn:focus {
  border-color: #00bea4;
  box-shadow: 0 0 0 0.25rem rgba(0, 190, 164, 0.5) !important;
}
.custer {
  background: #00bea4;
  color: #fff;
  width: 100%;
  outline: 0;
}
</style>